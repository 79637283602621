<template>
<div class="card">
    <div class="card-header border-0 pt-6 bg-white row m-0 sticky">
        <div class="d-flex p-0">
           <div class="card-title">
                <form class="filter d-flex align-items-center position-relative fs-6 text-gray-600" @submit.prevent>       
                    <div class="filter-item">
                        <span>Tanggal</span>
                        <el-date-picker
                          class="form-control"
                          v-model="filter.date"
                          type="daterange"
                          unlink-panels
                          range-separator="-"
                          start-placeholder="Start date"
                          end-placeholder="End date"
                          :shortcuts="dateShortcuts"
                          :size="'large'"
                          clearable
                          @change="onFilter()"
                          format="DD/MM/YYYY"
                          value-format="YYYY-MM-DD"
                        />
                    </div>
                    <div class="filter-item">
                        <span>Toko</span>
                        <el-select v-model="filter.warehouse_id" placeholder="Pilih Toko" @clear="onFilter()" clearable @change="onFilter">
                            <el-option v-for="(o, i) in listWarehouse" :key="i" :value="o.id" :label="o.name"/>
                        </el-select>
                    </div>
                    <div class="filter-item">
                        <span>FOLDER</span>
                        <el-select v-model="filter.folder_id" placeholder="Pilih Folder" clearable>
                            <el-option v-for="(o, i) in listFolder" :key="i" :value="o.id" :label="o.name">
                                <span style="float: left">{{ o.name }}</span>
                                <span style="float: right">({{ o.total }})</span>
                            </el-option>
                        </el-select>
                    </div>
                    <div class="filter-item">
                        <span>Jenis</span>
                        <el-select v-model="filter.jenis" placeholder="Pilih jenis" clearable style="width: 150px;" @change="onFilter">
                            <el-option v-for="(o, i) in [{name:'Qty In', value:'plus'}, {name:'Qty Out', value:'minus'}]" :key="i" :value="o.value" :label="o.name" />
                        </el-select>
                    </div> 
                    <div class="filter-item">
                        <span>Cari</span>
                        <el-input v-model="filter.search" placeholder="Search" clearable @change="onFilter" @clear="onFilter" />
                    </div>
                    <div class="d-flex">
                        <button type="submit" class="btn btn-icon btn-light-primary me-3" @click="onFilter('desain')"><i class="fas fa-search"></i></button>
                        <button class="btn btn-icon btn-light-danger" @click="resetFilter('desain')"><i class="fas fa-times"></i></button>
                    </div>
                </form>
            </div>
            <div class="card-toolbar" style="margin-left:auto">
                <div class="d-flex justify-content-end" data-kt-table-toolbar="base">
                    <button class="btn btn-light btn-active-primary me-3" @click="getData()" v-show="false">Refresh</button>
                    <button type="button" class="btn btn-light-primary me-3" data-bs-toggle="modal" data-bs-target="#kt_customers_export_modal" v-show="false">
                        <span class="svg-icon svg-icon-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="black" />
                                <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="black" />
                                <path d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#C4C4C4" />
                            </svg>
                        </span>
                        Export
                    </button>   
                </div>
            </div>
        </div>
    </div>
    <div class="card-body pt-0 tab-content" id="tab_raw_product">
        <div ref="tableRef" class="table-fixed-header">
            <table class="table table-rounded table-row-dashed cursor-pointer table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                <thead>
                    <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                        <th class="w-50px">#</th>
                        <th class="w-200px">Tanggal</th>
                        <th>Warehouse</th>
                        <th>Desain ID</th>
                        <th>Folder</th>
                        <th>Keterangan</th>
                        <th class="text-center w-100px">Qty In</th>
                        <th class="text-center w-100px">Qty Out</th>
                    </tr>
                </thead>
                <tbody class="fw-bold text-gray-600">
                    <tr v-for="(item, i) in tableData" :key="i">
                        <td scope="row">{{ i+1 }}</td>
                        <td>{{ item.created_at }}</td>
                        <td>{{ item.warehouse }}</td>
                        <td>{{ item.desain_id }}</td>
                        <td>{{ item.folder }}</td>
                        <td>{{ item.notes || '-' }}</td>
                        <td class="text-center w-100px">{{ (item.qty &gt; 0) ? Math.abs(item.qty) : 0 }}</td>
                        <td class="text-center w-100px">{{ (item.qty &lt; 0) ? Math.abs(item.qty) : 0 }}</td>
                    </tr>
                </tbody>
                <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </table>
        </div>
        <div class="text-center my-3">
            <el-pagination 
                background
                layout="prev, pager, next" 
                :page-count="pagination.total_page" 
                @next-click="handlePageChange" 
                @prev-click="handlePageChange" 
                @current-change="handlePageChange" 
                @update:current-page="pagination.page"
            />
        </div>
    </div>

</div>
</template>

<script>
import { defineComponent, onBeforeMount, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useRoute, useRouter } from 'vue-router'
import { ElLoading, Elpagination } from 'element-plus'
//import { InfoFilled } from '@element-plus/icons-vue'
import moment from 'moment'
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

export default defineComponent({
    components: { 
        // Datatable,
    },
    setup() {
        const base_img_url = ref(process.env.VUE_APP_IMAGE_PRODUCTS)
        const loadingTable = ref(true);
        
        const search = ref(null)
        
        const [route, router, store] = [useRoute(), useRouter(), useStore()]
        
        const tableData = reactive([])
        const totalRecordCount = ref(0)
        const tableRef = ref(0)

        // Filter
        const filterActive = ref(false)
        const filter = reactive({
            date: null,
            warehouse_id: null,
            folder_id: null,
            jenis: null,
            search: null,
        })

        const stock_left = ref([])

        // PAGINATION
        const pagination = ref({
            per_page: 50,
            total_page: 0,
            total_row: 0,
            page: 1,
        })

        const handlePageChange = async (page) => {
            loadingTable.value = true
            tableRef.value && (tableRef.value.scrollTop = 0) // Reset scroll position
            await getData(page)
            //loadingTable.value = false
        }

        const getData = async() => {
            try {
                loadingTable.value = true
                tableData.splice(0)
                
                let page = pagination.value.page ? '?page=' + pagination.value.page : '?page=1';
                let start_date = filter.date ? '&start_date=' + moment(filter.date[0]).format('YYYY-MM-DD') : '';
                let end_date = filter.date ? '&end_date=' + moment(filter.date[1]).format('YYYY-MM-DD') : '';
                let store = filter.store_id ? '&store=' + filter.store_id : '';
                let folder = filter.folder_id ? '&folder=' + filter.folder_id : '';
                let jenis = filter.jenis ? '&jenis=' + filter.jenis : '';
                let search = filter.search ? '&search=' + filter.search : '';

                await ApiService.get("inventory/history_so_desain"+page + start_date + end_date + store + folder + jenis + search)
                .then(({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages);
                    }
                    
                    pagination.value = data.data.pagination;

                    Object.assign(tableData, data.data.data)
                })
                .catch((response) => {
                    throw new Error(response);
                });
                
            } catch(e) {
                console.log(e.message)
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }

            loadingTable.value = false
        }
        
        // Filter
        const onFilter = async () => {
            loadingTable.value = true

            filterActive.value = false

            await getData()

            Object.values(filter).every(o => o == null || o.length == 0)
                ? filterActive.value = false
                : filterActive.value = true
        }

        const resetFilter = async () => {
          Object.keys(filter).map(key => filter[key] = null)

          filterActive.value = false
          await getData()
        }

        const listFolder = computed(() => store.getters.getListRawFolder)
        const listWarehouse = computed(() => store.getters.getListWarehouse)

        onBeforeMount(() => {
            Object.assign(filter, store.getters.getFilter);

            getData()

            store.dispatch(Actions.LIST_RAW_FOLDER);
            store.dispatch(Actions.LIST_WAREHOUSE);
        })

        onMounted( async() => {
            setCurrentPageTitle("Riwayat  - SO Desain");
            setCurrentPageBreadcrumbs("Riwayat SO Desain", ['Stok Desain']);
        })

        return {
            loadingTable, route, router,
            tableData, totalRecordCount, tableRef,
            filter, filterActive, onFilter, resetFilter, listFolder, listWarehouse,
            handlePageChange, pagination,
        }
    }
})
</script>